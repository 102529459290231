import styled from "styled-components";

export namespace styles {
  export const StyledTr = styled.tr<{ depth?: boolean }>`
    text-align: left;
    height: 56px;
    background-color: ${(props) => (props.depth ? "white" : "#E5D5FA4D")};
  `;
  export const StyledTd = styled.td<{ width?: number }>`
    width: ${(props) => (props?.width ? props?.width + "%" : "14%")};
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
  export const ModalUrlTitle = styled.div`
    color: #1a0989;
    font-family: ${(props) => props?.theme?.fonts?.roboto?.regular};
    font-size: 19px;
    margin-left: 6px;
  `;
  export const StyledTitleTd = styled.td<{ width?: number }>`
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
  export const StyledTdURL = styled.td`
    width: 11%;
    text-align: center;
  `;
  export const ImageWraper = styled.div`
    margin: 0 7px 0 0;
  `;
  export const Url = styled.a`
    text-decoration: underline;
    color: #3955ef;
    padding: 20px 15px;
  `;
  export const ModalHeaderFlex = styled.div`
    display: flex;
    padding: 20px 8px;
  `;
  export const UrlRow = styled.div`
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
  `;
  export const StyledDiv = styled.div`
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
  export const StyledDivHolder = styled.div`
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    text-overflow: ellipsis;
  `;
  export const CampaignTitleHolder = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
  `;
  export const Edit = styled.div`
    text-align: center;
    letter-spacing: 0px;
    color: white;
    background-color: #7031eb;
    border-radius: 15px;
    width: 60px;
    &:hover {
      cursor: pointer;
    }
  `;
  export const TableHolder = styled.div`
    width: 95%;
    max-height: 75vh;
    overflow-y: auto;
    margin: 0 auto;
    padding: 0px 0 65px 0;
    border-radius: 30px;
    background-color: white;
    box-shadow: 2px 13px 54px #695f9714;
  `;
  export const StyledTh = styled.th<{ w?: number; center?: boolean }>`
    text-align: center;
    color: #7a86a1;
    font-size: 15px;
    position: sticky;
    top: 0;
    background-color: white;
    padding: ${(props) => (props?.center ? "0.5rem 2.5rem" : "0.5rem")};

    text-align: ${(props) => (props?.center ? "left" : "center")};

    max-width: ${(props) => (props?.w ? props?.w + "%" : "")};
  `;
  export const StyledHeaderTr = styled.tr`
    border-bottom: 1px solid #d4d7df;
    height: 45px;
    background-color: white;
    position: sticky;
    width: 100%;
  `;
  export const StyledThead = styled.thead`
    position: sticky;
    padding: 0 10px;
  `;

  export const DefaultBadge = styled.div`
    width: max-content;
    border-radius: 10px;
    font-size: 10px;
    background-color: #f7f2fd;
    color: #7031eb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    height: 80%;
    margin-left: 5px;
    margin-top: 5px;
  `;
  export const URLbutton = styled.button`
    width: 50%;
    border-radius: 15px;
    border: 1px solid #1a0989;
    color: #1a0989;
    font-size: 14px;
    margin: 0 auto;
  `;
  export const StyledTable = styled.table`
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
    /*  minWidth: 500px;
    minHeight: 300px;*/
  `;
}

export default styles;
