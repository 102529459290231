import integrationsTableStyles from "./styles";
import { useTranslation } from "react-i18next";
import ReactTimeAgo from "react-time-ago";

import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tbody,
  Text,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import {
  getIntegrationPlatformName,
  getIntegrationPlatformRoundLogo,
  getIntegrationStatusImage,
  getIntegrationStatusText,
  Integration,
  IntegrationPlatform,
  IntegrationStatus,
  IntegrationType,
} from "@models";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIntegrationName,
  cleanSavedIntegration,
  deleteIntegration, getAmazonAdsAccounts,
  organizationIntegrationsSelector,
  organizationLoadingSelector,
  organizationSelector,
  organizationStateSelector,
} from "@store";
import { useEffect, useState } from "react";
import { MoreInfoDialogProps } from "dialogs/more-info-dialog/MoreInfoDialog";
import MoreInformation from "components/more-information/MoreInformation";
import { accountStateSelector } from "../../../store/ui";
import { systemEventStateSelector } from "../../../store/system-events";
import {
  changeChannelName,
  channelsStateSelector,
} from "../../../store/channels";
import { AmazonAdsRegions } from "../../../store/amazon-ads";
import RefreshOAuth from "../../../components/refresh-oauth/RefreshOAuth";
import { organizationService } from "@services";
import { facebookAdsAuthenticate } from "../../../store/facebook";
import {
  BsChevronCompactDown,
  BsChevronCompactUp,
  BsPencilFill,
} from "react-icons/bs";
import { FiMoreVertical } from "react-icons/fi";
import { updateAccountDisplayName } from "../../../store/ui/thunks";

interface integrationRow extends Integration {
  subRow?: boolean;
  subRows?: any[];
  channelId?: string;
  storeName?: string;
  wvAccountId?: string;
}

interface integrationProps {
  integration: integrationRow;
  index: number;
}

interface ISortState {
  column: any;
  direction: 'asc' | 'desc' | null;
}

const myIntegrationsMoreInfoDialogProps = {
  title: "My Integrations",
  //subtitle: 'How to use this view?',
  description: "More integrations = more data = more insights.",
  tip: "TIP: More integrations = more data = more insights.",
  /*videoUrl: 'https://www.youtube.com/watch?v=Rjm129AoRno',
    videoTitle: 'Best Practices',
    videoSubtitle: 'How to use this view?',
    videoDescription: '5 min explanatory video from our COO',
    videoLinkTitle: 'Click to watch'*/
} as MoreInfoDialogProps;

export const IntegrationsTable = () => {
  // hooks
  const { t } = useTranslation(["translation", "translation"]);
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const organizationLoader = useSelector(organizationLoadingSelector);
  const channels = useSelector(channelsStateSelector);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const accounts = useSelector(accountStateSelector);
  const [extandesRows, setExtandesRows] = useState<number[]>([]);
  const integrations = useSelector(organizationIntegrationsSelector);
  const [integrationToDelete, setIntegrationToDelete] =
    useState<Integration | null>(null);
  const [deleteIntegrationModalOpen, setDeleteIntegrationModalOpen] =
    useState(false);
  const [
    isMyIntegrationsMoreInfoDialogOpen,
    setIsMyIntegrationsMoreInfoDialogOpen,
  ] = useState(false);
  const systemEvents = useSelector(systemEventStateSelector);
  useEffect(() => {
    dispatch(cleanSavedIntegration());
  }, []);
  const allChannels = useSelector(channelsStateSelector);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState<Integration | undefined>(undefined);

    const [sortState, setSortState] = useState<ISortState>({ column: null, direction: null });

  const handleSort = (clickedColumn: any) => {
    if (sortState.column === clickedColumn) {
      setSortState({ ...sortState, direction: sortState.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortState({ column: clickedColumn, direction: 'asc' });
    }
  };

  useEffect(() => {
    if(!isOpen){
      setSelectedIntegrationId(undefined);
    }
  }, [isOpen])

  //table
  const tableColumns: { name: string, key: string }[] = [
    {
      name: "Display Name",
      key: 'name'
    },
    {
      name: "Platform",
      key: 'platform'
    },
    {
      name: "Account",
      key: 'accountName'
    },
    {
      name: "Sales Channel",
      key: 'storeName'
    },
    {
      name: "Department",
      key: 'type'
    },
    {
      name: "Status",
      key: "status"
    },
  ];

  // helpers

  const handleIntegrationNameChange = (
    e: any,
    firstValue?: string,
    integrationId?: string,
    setSuccess?: any
  ) => {
    if (e.target.value !== firstValue) {
      const name = e.target.value;
      dispatch(
        changeIntegrationName({
          name: name,
          integrationId: integrationId,
          organizationId: organization?.id,
        })
      );
      if (!!setSuccess) {
        setSuccess(true);
      }

    }
  };

  const getNameFromIntegrationType = (type?: IntegrationType): string => {
    switch (type) {
      case IntegrationType.MARKETING:
        return "Marketing";
      case IntegrationType.STORES:
        return "Stores";
      case IntegrationType.FINANCE:
        return "Finance";
      case IntegrationType.CRM:
        return "CRM";
      case IntegrationType.OPERATION:
        return "Operation";
      case IntegrationType.WEBSITE:
        return "Website";
      case IntegrationType.TEAM_MANAGEMENT:
        return "Team Management";
      default:
        return "-";
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.target.blur(); // removes focus and goes to handleIntegrationNameChange function on default
    }
  };

  const openDeleteIntegrationModal = (integration: Integration) => {
    setIntegrationToDelete(integration);
    setDeleteIntegrationModalOpen(true);
  };

  const closeDeleteIntegrationModal = () => {
    setIntegrationToDelete(null);
    setDeleteIntegrationModalOpen(false);
  };

  // renderers
  const renderIntegrationsTableTitle = () => {
    return (
      <integrationsTableStyles.Title>
        {t("MY_INTEGRATIONS", { ns: "translation" })}
        <MoreInformation
          moreInfoDialogProps={myIntegrationsMoreInfoDialogProps}
          isMoreInfoDialogOpen={isMyIntegrationsMoreInfoDialogOpen}
          setIsMoreInfoDialogOpen={setIsMyIntegrationsMoreInfoDialogOpen}
        />
      </integrationsTableStyles.Title>
    );
  };


  const handleRegion = (initialString: string, integration: Integration) : string => {
    if(integration?.platform === IntegrationPlatform.AMAZON_SELLER && integration?.object?.regionCode !== undefined){
      let regionCode =  integration?.object?.regionCode;
      if(typeof regionCode === 'string'){
        regionCode = parseInt(regionCode);
      }

      initialString = initialString + ` ${getRegionByEnum(regionCode)}`
    }

    if(integration?.platform === IntegrationPlatform.SHOPIFY && integration?.object?.shop !== undefined){
      initialString = integration?.object?.shop
    }

    if(integration?.platform === IntegrationPlatform.WOOCOMMERCE && integration?.object?.store_url !== undefined){
      initialString = integration?.object?.store_url
    }

    return initialString
  }

  const renderIntegrationsTable = () => {
    let integrationsCopy: Integration[] = [];
    if(!!integrations){
      integrationsCopy = JSON.parse(JSON.stringify(integrations));
    }

    integrationsCopy = integrationsCopy?.map((integration) => {
      let stringToRender = '';
      stringToRender = handleRegion(stringToRender, integration);
      if(!!integration?.googleAdsAccounts && integration?.googleAdsAccounts?.length > 0 && integration?.platform === IntegrationPlatform.GOOGLE_ADS){
        const filteredAccounts = integration?.googleAdsAccounts?.filter((account) => account.isActive  && !!account?.descriptiveName);
        if(filteredAccounts?.length > 0){
          stringToRender = filteredAccounts?.map((account) => {
            return account?.descriptiveName + ' (' + account?.id + ')'
          }).join(', ');
        }
      }
      if(!!integration?.facebookAdsAccounts &&integration?.facebookAdsAccounts?.length > 0 && integration?.platform === IntegrationPlatform.FACEBOOK_ADS){
        const filteredAccounts = integration?.facebookAdsAccounts?.filter((account) => account.isActive  && !!account?.name);
        if(filteredAccounts?.length > 0){
          stringToRender = filteredAccounts?.map((account) => {
            return account?.name + ' (' + account?.account_id + ')'
          }).join(', ');
        }
      }
      return {...integration, accountName: stringToRender}


    })


    const desiredOrder = [IntegrationType.STORES, IntegrationType.MARKETING, IntegrationType.TEAM_MANAGEMENT,
     IntegrationType.OPERATION, IntegrationType.WEBSITE, IntegrationType.FINANCE, IntegrationType.CRM];

    const sortedIntegrations = integrationsCopy.sort((a, b) => {
      // Find the index of the types in the desired order array
      const typeIndexA = desiredOrder.indexOf(a?.type ?? -1);
      const typeIndexB = desiredOrder.indexOf(b?.type ?? -1);
      // Compare the type indices
      const typeDiff = typeIndexA - typeIndexB;
      if (typeDiff !== 0) {
        return typeDiff;
      }
      // If types are equal, sort by platform
      const platformDiff = (a?.platform ?? 0) - (b?.platform ?? 0);
      if (platformDiff !== 0) {
        return platformDiff;
      }
      // If types and platforms are equal, maintain current order
      return 0;
    });

    const sortedData = sortedIntegrations?.sort((a, b) => {
      const direction = sortState.direction === 'asc' ? 1 : -1;
      if (a[sortState.column!] < b[sortState.column!]) {
        return -1 * direction;
      } else if (a[sortState.column!] > b[sortState.column!]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });


    const tableRows = sortedData?.map((row) => {

      const foundSubRows = channels?.channels?.filter((channel) => channel?.integrationId === row?.id);

      if(row?.platform === IntegrationPlatform.AMAZON_ADS){

        let accountNames = '';
        let channelNames = '';
        const allFoundAccountsForAmazonAds = accounts?.data?.filter((account) => account?.integrationId === row?.id);

        const mappedAmazonAdsSubRows = allFoundAccountsForAmazonAds?.map((profile) => {
          const accountName = profile?.displayName;
          const countryCode = profile?.countryCode;
          const name = accountName + ` - ${profile?.countryCode}`;
          const storeName = profile?.name;
          return {
            ...profile,
            wvAccountId: profile?.id,
            subRow: true,
            amazonAdsProfile: true,
            name: name,
            platform: IntegrationPlatform.AMAZON_ADS,
            accountName: storeName,
            storeName: countryCode + ` (${profile?.accountId})`
          }
        });

        const uniqueAccountNames = new Set(allFoundAccountsForAmazonAds.map(profile => profile.countryCode));

        uniqueAccountNames.forEach(accountName => {
          if (accountNames !== '') {
            accountNames += ', ';
          }
          accountNames += accountName;
        });

        const uniqueChannels = new Set(mappedAmazonAdsSubRows.map(profile => profile.accountName));

        uniqueChannels.forEach(channel => {
          if (channel !== '') {
            channel += ', ';
          }
          channelNames += channel;
        });

        row['accountName'] = channelNames

        row['storeName'] = accountNames

        return {...row, subRows: mappedAmazonAdsSubRows}

      }

      const mappedSubRows = foundSubRows?.map((subRow) => {
        return {...subRow, subRow: true, id: row?.id, channelId: subRow.id, name: subRow.displayName, storeName: subRow.name}
      })
      return {...row, subRows: mappedSubRows}
    })



    return (
      <integrationsTableStyles.IntegrationsTableWrapper>
        <integrationsTableStyles.IntegrationsTable>
          {renderIntegrationsTableHeader()}
          <Tbody maxH={50}>
            {tableRows?.map((integration, index) => {
             return (
                 <>
                   <RenderIntegrationsTableRow
                       integration={integration}
                       index={index}
                   />
                   {integration?.subRows?.length > 0 && extandesRows?.includes(index) &&
                       integration.subRows.map((subRow, subIndex) => (
                           <RenderIntegrationsTableRow
                               integration={subRow}
                               index={parseFloat(`${index}.${subIndex+1}`)}
                           />
                       ))}
                 </>
             )
            })}
          </Tbody>
        </integrationsTableStyles.IntegrationsTable>
      </integrationsTableStyles.IntegrationsTableWrapper>
    );
  };

  const renderIntegrationsTableHeader = () => {
    return (
      <Thead backgroundColor={"white"} fontSize={14}>
        <Tr backgroundColor={"white"}>
          {tableColumns.map((tableColumn, index) => (
            <integrationsTableStyles.TableTh  onClick={() => handleSort(tableColumn.key)} key={index}>
              {tableColumn.name}
            </integrationsTableStyles.TableTh>
          ))}
        </Tr>
      </Thead>
    );
  };

  const RenderIntegrationsTableRow = (props: integrationProps) => {
    const [focused, setFocused] = useState(false);
    const [success, setSuccess] = useState(false);
    const [id, setId] = useState<string>("");
    const organization = useSelector(organizationStateSelector);

    const allAccountsOfIntegration = accounts?.data?.filter(
      (item) => item?.integrationId === props?.integration?.id
    );
    let stringToRender = allAccountsOfIntegration
      ?.map((accountName, i) => {
        if (allAccountsOfIntegration?.length - 1 === i) {
          return accountName?.displayName;
        } else {
          return accountName?.displayName + ", ";
        }
      })
      .join("");

    const nameTd = () => {
      const copyOfIntegration : any = {...props?.integration};
      /*const nonEditable = copyOfIntegration?.subRow && copyOfIntegration?.amazonAdsProfile

      if(nonEditable){
        return <integrationsTableStyles.TableTd>
          <Flex w={'90%'} h={'100%'} alignItems={'center'} justifyContent={'space-between'}>
            <integrationsTableStyles.NameInputDisabled disabled={true} value={props.integration?.name}>
            </integrationsTableStyles.NameInputDisabled>
          </Flex>
        </integrationsTableStyles.TableTd>
      }*/

      return (
          <integrationsTableStyles.TableTd>
            <Flex
                w={"90%"}
                h={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
              <integrationsTableStyles.NameInput
                  onFocus={(e) => {
                    setFocused(true);
                  }}
                  onKeyPress={(e) => handleKeyPress(e)}
                  onBlur={(e) => {


                    if(props?.integration?.subRow){
                      const body = [{
                        displayName: e?.target?.value,
                        id: props?.integration?.channelId
                      }];
                      
                      if(props?.integration?.platform === IntegrationPlatform.AMAZON_ADS){
                        const body = [{
                          displayName: e?.target?.value,
                          id: props?.integration?.id
                        }];
                        !!organization?.organization?.id && !!e?.target?.value && !!props?.integration?.wvAccountId &&
                            dispatch(updateAccountDisplayName({orgId: organization?.organization?.id, accountId:props?.integration?.wvAccountId , displayName: e?.target?.value }))
                      }
                      else {
                        !!organization?.organization?.id && dispatch(changeChannelName({ organizationId: organization?.organization?.id, body }))
                      }


                      setFocused(false);
                      return
                    }
                    handleIntegrationNameChange(
                        e,
                        props.integration?.name,
                        props.integration.id,
                        setSuccess
                    );

                    const foundChannelId = channels?.channels?.find((channel) => channel?.integrationId === props?.integration?.id)?.id;
                    if(!!foundChannelId){
                      const body = [{
                        displayName: e?.target?.value,
                        id: foundChannelId
                      }];
                      !!organization?.organization?.id && dispatch(changeChannelName({ organizationId: organization?.organization?.id, body }))
                    }



                    !!props?.integration?.id && setId(props.integration.id);
                    setFocused(false);
                  }}
                  defaultValue={props.integration?.name}
              />
              <Box w={10} />
              {focused ? (
                  <Box opacity={0}>
                    <BsPencilFill />
                  </Box>
              ) : (
                  <BsPencilFill />
              )}
            </Flex>
          </integrationsTableStyles.TableTd>
      )
    }
    const departmentTd = () => {
      return (
          <integrationsTableStyles.TableTd>
            <Box ml={5}>
              {!props?.integration?.subRow && getNameFromIntegrationType(props?.integration?.type)}
            </Box>
          </integrationsTableStyles.TableTd>
      )
    }
    const platformTd = () => {
      return (
          <integrationsTableStyles.TableTd w={170}>
            <Box ml={5}>{renderTypeInTable(props?.integration)}</Box>
          </integrationsTableStyles.TableTd>
      )
    }

    const accountTd = () => {

      return (
          <integrationsTableStyles.TableTd>
            <Flex justifyContent={'space-between'} alignItems={'center'} ml={5}>
              {allAccountsOfIntegration?.length === 0 && !props?.integration?.accountName ?
                  (
                  "-"
              ) : stringToRender?.length > 80 ? (
                  <Tooltip label={props?.integration?.accountName}>
                    {props?.integration?.accountName?.slice(0, 77) + "..."}
                  </Tooltip>
              ) : (
                      props?.integration?.accountName
              )}
            </Flex>
          </integrationsTableStyles.TableTd>
      )
    }

    const statusTd = () => {
      return (
          <integrationsTableStyles.TableTd width={300}>
            <Box ml={5}>
              {renderStatusInTable(
                  props?.integration,
                  props?.integration?.updatedAt?.slice(0, 10)
              )}
            </Box>
          </integrationsTableStyles.TableTd>
      )
    }

    const storeNameTd = () => {

      const amazonSellerChannelsMapping = {
    /*# North America*/
      'ATVPDKIKX0DER': 'Amazon.com',
          'A2EUQ1WTGCTBG2': 'Amazon.ca',
          'A1AM78C64UM0Y8': 'Amazon.com.mx',
          'A2Q3Y263D00KWC': 'Amazon.com.br',
          'A2ZV50J4W1RKNI': 'Non-Amazon US',
          'A3H6HPSLHAK3XG': 'Non-Amazon MX',
          'AHRY1CZE9ZY4H': 'Amazon.com Invoicing Shadow Marketplace',
          'A1MQXOICRS2Z7M': 'SI CA Prod Marketplace',


    /*# Europe*/
      'A13V1IB3VIYZZH': 'Amazon.fr',
          "A1805IZSGTT6HS": "Amazon.nl",
          "A1C3SOZRARQ6R3": "Amazon.pl",
          'A1F83G8C2ARO7P': 'Amazon.co.uk',
          'A1PA6795UKMFR9': 'Amazon.de',
          'A1RKKUPIHCS9HS': 'Amazon.es',
          'A1ZFFQZ3HTUKT9': 'Non-Amazon FR',
          "A2NODRKZP88ZB9": "Amazon.se",
          "A38D8NSA03LJTC": 'Non-Amazon DE',
          'A62U237T8HV6N': 'SI Prod IT Marketplace',
          'AFQLKURYRPEL8': 'SI Prod ES Marketplace',
          'AMEN7PMS3EDWL': 'Amazon.com.be',
          "APJ6JRA9NG5V4": "Amazon.it",
          'AZMDEXL2RVFNN': 'SI UK Prod Marketplace',
          "A33AVAJ2PDY3EV": "Amazon.com.tr",
          "A17E79C6D8DWNP": "Amazon.sa",
          "A21TJRUUN4KGV": "Amazon.in",
          "ARBP9OOSHTCHU": "Amazon.eg",
          "A2VIGQ35RCS4UG": "Amazon.ae",
    /*# Far East*/
      "A1VC38T7YXB528": "Amazon.jp",
          "A39IBJ37TRP1C6": "Amazon.com.au",
          "A19VAU5U5O7RUS": "Amazon.sg",
      }


        const getName = (name?: string) : string | undefined=> {
          if(!!name &&  name in amazonSellerChannelsMapping){
              return amazonSellerChannelsMapping[name]
          }
          return name

        }


      return (
          <integrationsTableStyles.TableTd pointer={!!props?.integration?.subRows && props?.integration?.subRows?.length > 0} onClick={() => {

            if(!props?.integration?.subRow){
              if(extandesRows?.includes(props?.index)){
                setExtandesRows(prev => prev.filter(rowIndex => rowIndex !== props.index));
              }else {
                setExtandesRows(prev => [...prev, props.index]);
              }
            }
          }}>
              <Box ml={5} w={'95%'}>
                {props?.integration?.subRow || props?.integration?.storeName ? getName(props?.integration?.storeName) : allChannels?.channels
                    ?.filter((item) => item?.integrationId === props?.integration?.id)
                    ?.map((item, index, array) => {
                      return `${getName(item?.name)}${index !== array.length - 1 ? ", " : ""}`;
                    })
                    .join("")}
              </Box>

            <integrationsTableStyles.ArrowHolder>
              {!!props?.integration?.subRows && props?.integration?.subRows?.length > 0 ?
                extandesRows?.includes(props?.index) ? <BsChevronCompactDown /> : <BsChevronCompactUp />
              : ""}
            </integrationsTableStyles.ArrowHolder>
          </integrationsTableStyles.TableTd>
      )
    }

    return (
      <integrationsTableStyles.TableTr index={props.index} key={props.index}>
        {nameTd()}
        {platformTd()}
        {accountTd()}
        {storeNameTd()}
        {departmentTd()}
        {statusTd()}
      </integrationsTableStyles.TableTr>
    );
  };

  const getRegionByEnum = (regionKey: number) :string => {
    switch (regionKey){
      case (AmazonAdsRegions.NORTH_AMERICA):
        return 'NA'
      case (AmazonAdsRegions.EUROPE):
        return 'EU'
      case (AmazonAdsRegions.FAR_EAST):
        return 'FE'
      default:
        return ''
    }
  }

  const renderTypeInTable = (integration?: Integration) => {
    let image = getIntegrationPlatformRoundLogo(integration?.platform);
    let title = getIntegrationPlatformName(integration?.platform);



    return (
      <Flex direction={"row"} alignItems={"center"}>
        <Image src={image} width={"auto"} height={"20px"} marginRight={5} />
        <Text>{t(title ?? "", { ns: "translation" })}</Text>
      </Flex>
    );
  };

  const renderStatusIcon = (status: IntegrationStatus) => {
    return (
      <integrationsTableStyles.StatusIcon
        src={getIntegrationStatusImage(status)}
      />
    );
  };

  const renderStatusInTable = (integration: any, lastUpdate?: string) => {
    const foundSystemEvent = systemEvents?.integrationData?.find(
      (item) => item?.data?.integrationId === integration?.id
    );
    return (
      <integrationsTableStyles.StatusWrapper>
        {renderStatusIcon(
          foundSystemEvent?.data?.status ?? IntegrationStatus.CONNECTED
        )}
        <integrationsTableStyles.StatusInfo>
          <integrationsTableStyles.StatusText>
            {t(
              getIntegrationStatusText(
                foundSystemEvent?.data?.status ?? IntegrationStatus.CONNECTED
              ) ?? "",
              { ns: "translation" }
            )}
          </integrationsTableStyles.StatusText>
          <integrationsTableStyles.StatusLastUpdatedText>
            {t("LAST_UPDATE", { ns: "translation" }) + " "}
            {!!foundSystemEvent?.updatedAt &&
            !!Date.parse(foundSystemEvent?.updatedAt) ? (
              <ReactTimeAgo date={Date.parse(foundSystemEvent?.updatedAt)} />
            ) : (
              ""
            )}
          </integrationsTableStyles.StatusLastUpdatedText>
          <integrationsTableStyles.StatusLastUpdatedText>
            {`Data starting at: ${integration?.firstDayOfData ?? ""}`}
          </integrationsTableStyles.StatusLastUpdatedText>
        </integrationsTableStyles.StatusInfo>
        <Spacer />
        {!integration?.subRow && renderButtonMoreButton(integration)}
      </integrationsTableStyles.StatusWrapper>
    );
  };

  const renderButtonMoreButton = (integration: Integration) => {
    return (
      <integrationsTableStyles.More>
        <Menu>
          <MenuButton>
            <Icon as={FiMoreVertical} marginLeft={"auto"} fontSize={20} />
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                if (
                  integration?.platform === IntegrationPlatform.FACEBOOK_ADS
                ) {
                  dispatch(facebookAdsAuthenticate({isUpdate: true, integrationId: integration?.id}));
                }
              }}
            >
              {integration.platform === IntegrationPlatform.SHOPIFY ?
                 <RefreshOAuth
                  integration={integration?.platform}
                  onAuthorization={(params) => {
                    const shopifyOAuthData = params.get("result");

                    let shopifyParsedOAuthData = null;
                    if (!!shopifyOAuthData) {
                      shopifyParsedOAuthData = JSON.parse(shopifyOAuthData);
                      try {
                        !!organization?.id &&
                          !!integration?.id &&
                          organizationService.refreshIntegration({
                            organizationId: organization?.id,
                            integrationId: integration?.id,
                            integration: {
                              platform: integration?.platform,
                              object: shopifyParsedOAuthData,
                            },
                          });
                      } catch (e) {
                        console.log(e);
                      } finally {
                      }
                    }
                  }}
                />
                  : <>Refresh</>
              }
            </MenuItem>

              {integration?.platform === IntegrationPlatform.AMAZON_ADS && <MenuItem onClick={() => {
                if(!!organization?.id && !!integration?.id){
                  dispatch(getAmazonAdsAccounts({organizationId: organization?.id, integrationId: integration?.id}))
                }
              }}>Refresh Accounts</MenuItem>}
            <MenuItem onClick={() => openDeleteIntegrationModal(integration)}>
              {t("DELETE", { ns: "translation" })}
            </MenuItem>
          </MenuList>
        </Menu>
      </integrationsTableStyles.More>
    );
  };



  const renderDeleteIntegrationModal = () => {
    return (
      <Modal
        isOpen={deleteIntegrationModalOpen}
        onClose={() => closeDeleteIntegrationModal()}
      >
        <ModalOverlay />
        <ModalContent borderRadius={10}>
          <ModalHeader>{t("ATTENTION", { ns: "translation" })}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {t("ARE_YOU_CONFIRM_TO_DELETE_INTEGRATION?", { ns: "translation" })}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => closeDeleteIntegrationModal()}>
              {t("CLOSE", { ns: "translation" })}
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                if (!!organization?.id && !!integrationToDelete?.id) {
                  dispatch(
                    deleteIntegration({
                      organizationId: organization?.id,
                      integrationId: integrationToDelete?.id,
                      onSuccess: () => {
                        closeDeleteIntegrationModal();
                      },
                    })
                  );
                } else {
                  closeDeleteIntegrationModal();
                }
              }}
            >
              {t("CONFIRM", { ns: "translation" })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <integrationsTableStyles.Wrapper>
      {/*<GoogleAdsAccountsModal onClose={onClose} onOpen={onOpen} isOpen={isOpen} integration={selectedIntegrationId}/>*/}
      {/* <integrationsTableStyles.SmallerWrapperBackground> */}
      {renderIntegrationsTableTitle()}
      {renderIntegrationsTable()}
      {renderDeleteIntegrationModal()}
      {/* </integrationsTableStyles.SmallerWrapperBackground> */}
    </integrationsTableStyles.Wrapper>
  );
};

