import styles from "./styles"
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {tooltipsStateSelector} from "../../store/tooltips";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    Flex, Box, Portal,
} from '@chakra-ui/react'
import MoreInformation from "../more-information/MoreInformation";
import {MoreInfoDialogProps} from "../../dialogs/more-info-dialog/MoreInfoDialog";
import './popover.css'


export enum Namespace {
    GENERAL = 'general',
}

interface Props {
    text?: string;
    namespace?: Namespace
    displayLongText?: true;

    // for static tooltips
    provided?: boolean;
    shortText?: string;
    longText?: string;
    dontDisplayHeader?: boolean;
    width?: number;
    small?: boolean;
}

interface TooltipInterface {
    shortText?: string;
    longText?: string;
    ref?: string;
}

const InformationWrapper = (props: Props) => {
    const tooltips = useSelector(tooltipsStateSelector)?.data;
    const [foundTooltipState, setFoundTooltipState] = useState<TooltipInterface>();
    const [isMoreInformationModalOpen, setIsMoreInformationModalOpen,] = useState(false);
    const [moreInformationDialogProps, setMoreInformationDialogProps] = useState<MoreInfoDialogProps>()

    useEffect(() => {
        cleanUp()
    }, [])

    useEffect(() => {
        if (tooltips && !!props?.text) {
            findTooltipForText()
        }
    }, [tooltips, props?.text, props?.shortText]);

    const cleanUp = () => {
        setFoundTooltipState(undefined)
    };

    const handleSetMoreInfoDialogData = (longText: string) => {
        if (!!longText) {
            setMoreInformationDialogProps({
                title: props?.text,
                description: longText
            })
        }

    }

    const findTooltipForText = () => {
        if (!!props?.provided){
            let foundTooltipData: TooltipInterface = {
                shortText: props?.shortText,
                longText: props?.longText
            }
            setFoundTooltipState(foundTooltipData);
            if (foundTooltipData?.longText) {
                handleSetMoreInfoDialogData(foundTooltipData?.longText)
            }
        }
        if (!!tooltips && !!props?.text && Object.prototype.hasOwnProperty.call(tooltips, props?.text)) {
            let foundTooltipData: TooltipInterface = tooltips[props?.text]

            if('ref' in foundTooltipData && !!foundTooltipData['ref'] && foundTooltipData['ref'] in tooltips){
                foundTooltipData = tooltips[foundTooltipData['ref']];
            }

            setFoundTooltipState(foundTooltipData);
            if (foundTooltipData?.longText) {
                handleSetMoreInfoDialogData(foundTooltipData?.longText)
            }
        }
    };

    const PopoverBodyRender = ({htmlString}: { htmlString: string }) => {
        return (
            <styles.TooltipBody
                small={props?.small}
                 dangerouslySetInnerHTML={{__html: htmlString}}
            />
        );
    };

    const popoverContent = () => {

        return (
            <styles.StyledPortal >
                    <styles.StyledPopoverContent width={props?.width}>
                        <div id={'popover-content-wrapper'}>
                            <PopoverArrow/>
                            {props?.dontDisplayHeader ?<></> : <styles.Header>{props?.text}</styles.Header>}
                            {!!foundTooltipState?.shortText && <PopoverBodyRender htmlString={foundTooltipState?.shortText}/>}
                        </div>

                    </styles.StyledPopoverContent>
            </styles.StyledPortal>
        );
    }


    const renderMoreInformationModal = () => {
        return (
            <MoreInformation
                moreInfoDialogProps={moreInformationDialogProps!}
                isMoreInfoDialogOpen={isMoreInformationModalOpen}
                setIsMoreInfoDialogOpen={setIsMoreInformationModalOpen}
            />
        )
    }

    return (
        <Popover isLazy={true} lazyBehavior={'keepMounted'} closeOnEsc={true} closeOnBlur={true}
                 placement={'top'} trigger={'hover'}>
            <PopoverTrigger>
                <Flex alignItems={'center'}>
                    <Box>
                        {props?.text}
                    </Box>
                    {!!props?.displayLongText && !!moreInformationDialogProps && renderMoreInformationModal()}
                </Flex>
            </PopoverTrigger>
            {!!foundTooltipState?.shortText && popoverContent()}
        </Popover>
    );
};

export default InformationWrapper;
