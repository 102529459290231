import {
  Badge,
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { MarketingKpi } from "@models";
import {
  MarketingMappingSource,
  organizationDataLanguageMappingSelector,
  organizationDataLanguageSelector,
  organizationSelector,
  Product,
} from "@store";
import React, { Fragment, useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Cell, Row, useExpanded, useTable } from "react-table";
import { channelsStateSelector } from "store/channels";
import {
  kpiMarketingMappingSelector,
  kpiMarketingSelector,
  MarketingMappingData,
} from "store/kpis";
import { productsDataSelector } from "store/products";
import { accountStateSelector, isDefaultExploreOpenSelector } from "store/ui";
import {
  IconAmazon,
  IconFacebook,
  IconGoogleAds,
  IconTiktok,
} from "utils/icons/platforms";
import { MarketingModal } from "./marketing-modal/MarketingModal";
import { styles } from "./styles";
import { TargetBadge } from "../../target-badge/TargetBadge";
import { linksIcon } from "../../../utils/icons/links";

interface TableData {
  title?: string;
  salesChannel?: string[];
  products?: string[];
  url?: string[];
  type?: string;
  subRows?: TableData[];
  id?: string;
}

const MarketingTable = () => {
  const [row, setRow] = useState<any>();
  const [products, setProducts] = useState<{ id: number; name: string[] }[]>(
    []
  );
  const [channels, setChannels] = useState<{ id: number; name: string[] }>({
    id: 0,
    name: [""],
  });
  const [fetchedProducts, setFetchedProducts] = useState([] as Product[]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isUrlModalOpen,
    onOpen: onUrlModalOpen,
    onClose: onUrlModalClose,
  } = useDisclosure();
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState<MarketingMappingData[]>([]);
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const [dates, setDates] = useState<{ today: string; lastYear: string }>();
  const [urlModalData, setUrlModalData] = useState<{
    depth?: number;
    key?: string;
  }>({});

  const [channelsToAds, setChannelsToAds] = useState<any>();
  const accounts = useSelector(accountStateSelector);
  const marketingKpiSelector = useSelector(kpiMarketingMappingSelector);
  const dataLanguage = useSelector(organizationDataLanguageSelector);
  const channelsStore = useSelector(channelsStateSelector);
  const [allExpendedRows, setAllExpendedRows] = useState<{
    [key: string]: boolean;
  }>({});
  const isExploreOpen = useSelector(isDefaultExploreOpenSelector);
  useEffect(() => {
    setAllExpendedRows({});
  }, [isExploreOpen?.isDefaultExploreOpen]);

  useEffect(() => {
    if (!!marketingKpiSelector) {
      setData(marketingKpiSelector);
    }
  }, [marketingKpiSelector]);



  const organizationId = useSelector(organizationSelector);
  const productsData = useSelector(productsDataSelector);

  useEffect(() => {
    if (!!dataLanguage?.marketingMapping?.elements) {
      setChannelsToAds(dataLanguage?.marketingMapping?.elements);
    }
  }, [dataLanguage?.marketingMapping?.elements]);

  useEffect(() => {
    if (productsData !== undefined) {
      setFetchedProducts(productsData);
    }
  }, [productsData, dataLanguage?.marketingMapping?.elements]);

  // selectors

  const getPlatformToString = (number: string) => {
    switch (number) {
      case "FacebookAds":
        return "Facebook Ads";
      case "AmazonAds":
        return "Amazon Ads";
      case "GoogleAds":
        return "Google Ads";
    }
    return "Error";
  };

  const nestedData = (data: any[]) => {
    const fixedData: TableData[] = [];
    data?.forEach((AdDataTable, i) => {
      console.log(AdDataTable)
      //platform
      let foundPlatform: TableData | undefined = fixedData?.find(
        (fixedAdDataTable) =>
          fixedAdDataTable.title === getPlatformToString(AdDataTable?.platform)
      );
      if (!foundPlatform) {
        foundPlatform = {
          title: getPlatformToString(AdDataTable?.platform),
          salesChannel: [],
          products: [],
          url: [],
          type: "",
          subRows: [],
          data: [AdDataTable.platform, AdDataTable.wvAccountId],
          platform: true,
          id: AdDataTable.id,
        } as unknown as TableData;
        fixedData.push(foundPlatform);
      }
      const currentPlatform = fixedData?.filter(
        (item) => item.title === getPlatformToString(AdDataTable.platform!)
      );
      if (
        AdDataTable.url !== undefined &&
        currentPlatform[0]!.url!.filter((item) => item === AdDataTable.url![0])
          .length === 0
      ) {
        currentPlatform[0].url?.push(...AdDataTable.url);
      }
      /*  if (AdDataTable?.source !== undefined && currentPlatform[0]!.salesChannel!.filter((item) => item === AdDataTable?.source[0]).length === 0) {
                 (currentPlatform[0].salesChannel?.push(...AdDataTable?.source));
             }; */
      if (
        AdDataTable.product !== undefined &&
        currentPlatform[0]!.products!.filter(
          (item) => item === AdDataTable.product![0]
        ).length === 0
      ) {
        currentPlatform[0].products?.push(...AdDataTable.product);
      }

      //account
      let foundAccount: TableData | undefined = foundPlatform?.subRows?.find(
        (fixedAdDataTable) => {
          return AdDataTable.wvAccountId === fixedAdDataTable.title;
        }
      );
      if (!foundAccount) {
        foundAccount = {} as TableData;
        foundAccount = {
          title: AdDataTable?.wvAccountId,
          salesChannel: [],
          products: [],
          url: [],
          type: "",
          subRows: [],
          account: true,
          data: [
            getPlatformToString(AdDataTable.platform),
            AdDataTable.wvAccountId,
          ],
          accountId: AdDataTable.wvAccountId,
          id: AdDataTable.id,
        } as TableData;
        foundPlatform.subRows?.push(foundAccount);
      }
      const currentAccount = foundPlatform?.subRows?.find(
        (fixedAdDataTable) => {
          return AdDataTable.account === fixedAdDataTable.title;
        }
      );
      if (
        AdDataTable.url !== undefined &&
        currentAccount!.url!.filter((item) => item === AdDataTable.url![0])
          .length === 0
      ) {
        currentAccount!.url?.push(...AdDataTable.url);
      }
      /* if (AdDataTable?.source !== undefined && currentAccount!.salesChannel!.filter((item) => item === AdDataTable?.source[0]).length === 0) {
                (currentAccount!.salesChannel?.push(...AdDataTable.source));
            }; */
      if (
        AdDataTable.product !== undefined &&
        currentAccount!.products!.filter(
          (item) => item === AdDataTable.product![0]
        ).length === 0
      ) {
        currentAccount!.products?.push(...AdDataTable.product);
      }

      //campaign
      let foundCampaign: TableData | undefined = foundAccount?.subRows?.find(
        (fixedAdDataTable) => {
          return AdDataTable.campaignId === fixedAdDataTable.title;
        }
      );
      if (!foundCampaign) {
        foundCampaign = {
          title: AdDataTable?.campaignId,
          salesChannel: [],
          products: [],
          url: [],
          type: "",
          data: [
            getPlatformToString(AdDataTable.platform),
            AdDataTable.wvAccountId,
            AdDataTable.campaignId,
          ],
          subRows: [],
          id: AdDataTable.id,
          displayName: AdDataTable?.campaignName,
          campaignId: AdDataTable.campaignId,
        } as TableData;
        foundAccount.subRows?.push(foundCampaign);
      }
      const currentCampaign = foundAccount?.subRows?.find(
        (fixedAdDataTable) => {
          return AdDataTable.campaignId === fixedAdDataTable.title;
        }
      );
      if (
        AdDataTable.url !== undefined &&
        currentCampaign!.url!.filter((item) => item === AdDataTable.url![0])
          .length === 0
      ) {
        currentCampaign!.url?.push(...AdDataTable.url);
      }
      /*  if (AdDataTable.source !== undefined && currentCampaign!.salesChannel!.filter((item) => item === AdDataTable.source[0]).length === 0) {
                 (currentCampaign!.salesChannel?.push(...AdDataTable.source));
             }; */
      if (
        AdDataTable.product !== undefined &&
        currentCampaign!.products!.filter(
          (item) => item === AdDataTable.product![0]
        ).length === 0
      ) {
        currentCampaign!.products?.push(...AdDataTable?.product);
      }
    });
    return fixedData;
  };

  const getSvg = (name: string) => {
    switch (name) {
      case "Facebook Ads":
        return (
          <styles.ImageWraper>
            <IconFacebook />
          </styles.ImageWraper>
        );
      case "Google Ads":
        return (
          <styles.ImageWraper>
            <IconGoogleAds />
          </styles.ImageWraper>
        );
      case "TikTok":
        return (
          <styles.ImageWraper>
            <IconTiktok />
          </styles.ImageWraper>
        );
      case "Amazon Ads":
        return (
          <styles.ImageWraper>
            <IconAmazon />
          </styles.ImageWraper>
        );
    }
    return;
  };

  const getSalesChannelsById = (id?: string) => {
    const currentChannel = channelsStore?.channels?.filter(
      (item) => item?.id === id
    );
    return currentChannel?.map((item: any) => item?.displayName);
  };

  const marketingMappingElements = useSelector(
    organizationDataLanguageMappingSelector
  );
  const checkRow = (row: any) => {
    if (row?.depth === 0) {
      const allCampaignsUnderPlatform = row?.originalSubRows?.map((item: any) =>
        item?.subRows?.map((subItem: any) => subItem?.title)
      );
      const allAccountsUnderPlatform = row?.originalSubRows?.map(
        (item: any) => item?.accountId
      );

      console.log(marketingMappingElements)
      const allSelectedChannelsUnderPlatform = marketingMappingElements?.filter(
        (item: any) =>
          allAccountsUnderPlatform?.includes(item?.accountId) &&
          item?.salesChannels?.length > 0
      );
      const allChannels = allSelectedChannelsUnderPlatform
        ?.map((item: any) =>
          item?.salesChannels?.map((channel: any) => channel)
        )
        ?.flat();
      const filteredData = channelsToAds?.filter((item: any) =>
        allCampaignsUnderPlatform.flat()?.includes(item?.campaignId)
      );
      const salesChannels: string[] = [];
      filteredData?.forEach((obj: any) => {
        salesChannels.push(...obj.salesChannels);
      });
      const uniqueValues = salesChannels.filter(
        (v: any, i: number, a: any) => a.indexOf(v) === i
      );
      if (!!allChannels) {
        // defaults
        const newValues = [...uniqueValues, ...allChannels];
        // @ts-ignore
        const uniq: any[] = [...new Set(newValues)]?.filter((item) => !!item);
        const tooltipString = uniq
          ?.filter((item) => !!item)
          .map((item) => getSalesChannelsById(item))
          .join(", ");
        return (
          <Tooltip label={tooltipString}>
            <Flex alignItems={"center"}>
              {getSalesChannelsById(uniq[0])}
              {uniq?.length > 1 ? "..." : ""}
            </Flex>
          </Tooltip>
        );
        /* return uniq?.map((item, i) => <div
                    key={i}>{getSalesChannelsById(item)}</div>);*/
      } else {
        // @ts-ignore
        const uniq = [...new Set(uniqueValues)]?.filter((item) => !!item);
        const tooltipString = uniq
          ?.filter((item) => !!item)
          .map((item) => getSalesChannelsById(item))
          .join(", ");

        return (
          <Tooltip label={tooltipString}>
            <Flex alignItems={"center"}>
              {getSalesChannelsById(uniq[0])}
              {uniq?.length > 1 ? "..." : ""}
            </Flex>
          </Tooltip>
        );
        /* return uniq.map((item, i) => <div
                    key={i}>{getSalesChannelsById(item)}</div>);*/
      }
    }
    if (row?.depth === 1) {
      let seenNames: string[] = []

      const rowAccountId = row?.original?.data[1];
      const campaigns = row?.originalSubRows?.map((item: any) => item?.title);
      const filteredData = channelsToAds?.filter((item: any) =>
        campaigns?.includes(item?.campaignId)
      );
      const salesChannels: string[] = [];
      filteredData?.forEach((obj: any) => {
        salesChannels.push(...obj.salesChannels);
      });
      const uniqueValues = salesChannels.filter(
        (v: any, i: number, a: any) => a.indexOf(v) === i
      );
      const foundElements = marketingMappingElements?.filter(
        (item: any) => item?.accountId === rowAccountId
      );
      const foundSourceItem = foundElements?.find(
        (item) =>
          !!item?.salesChannels?.length && item?.salesChannels?.length > 0
      );
      const allChannels = foundElements
        ?.map((item) => item?.salesChannels?.map((channel) => channel))
        ?.flat();
      if (!!allChannels) {
        // defaults
        const newValues = [...uniqueValues, ...allChannels];
        // @ts-ignore
        const uniq = [...new Set(newValues)]?.filter((item) => !!item);
        const tooltipString = uniq
          ?.filter((item) => !!item)
          .map((item) => getSalesChannelsById(item))
          .join(", ");
        return (
          <Tooltip label={tooltipString}>
            <Flex alignItems={"center"}>
              {getSalesChannelsById(uniq[0])}
              {uniq?.length > 1 ? "..." : ""}
              {!!foundSourceItem &&
              foundSourceItem?.source === MarketingMappingSource.AUTO ? (
                <styles.DefaultBadge>Default</styles.DefaultBadge>
              ) : (
                <></>
              )}
            </Flex>
          </Tooltip>
        );
        return uniq.map((item, i) => (
          <Flex alignItems={"center"} key={i}>
            {getSalesChannelsById(item)}
            {!!foundSourceItem &&
            foundSourceItem?.source === MarketingMappingSource.AUTO ? (
              <styles.DefaultBadge>Default</styles.DefaultBadge>
            ) : (
              <></>
            )}
          </Flex>
        ));
      } else {
        // @ts-ignore
        const uniq = [...new Set(uniqueValues)]?.filter((item) => !!item);
        const tooltipString = uniq
          ?.filter((item) => !!item)
          .map((item) => getSalesChannelsById(item))
          .join(", ");
        return (
          <Tooltip label={tooltipString}>
            <Flex alignItems={"center"}>
              {getSalesChannelsById(uniq[0])}
              {uniq?.length > 1 ? "..." : ""}
              {!!foundSourceItem &&
              foundSourceItem?.source === MarketingMappingSource.AUTO ? (
                <styles.DefaultBadge>Default</styles.DefaultBadge>
              ) : (
                <></>
              )}
            </Flex>
          </Tooltip>
        );
        /*return uniq.map((item, i) => <Flex
                    alignItems={"center"}
                    key={i}>{getSalesChannelsById(item)}{!!foundSourceItem && foundSourceItem?.source === MarketingMappingSource.AUTO ? <styles.DefaultBadge>Default</styles.DefaultBadge> : <></>}</Flex>);*/
      }
    }
    if (row?.depth === 2) {
      const rowSalesChannels = channelsToAds?.filter(
        (item: any) => item?.campaignId === row?.original?.title
      );
      const salesChannels: string[] = [];
      rowSalesChannels?.forEach((obj: any) => {
        salesChannels.push(...obj.salesChannels);
      });

      const uniqueValues = salesChannels.filter(
        (v: any, i: number, a: any) => a.indexOf(v) === i
      );
      if (uniqueValues?.length > 0) {
        // @ts-ignore
        const uniq = [...new Set(uniqueValues)]?.filter((item) => !!item);
        return uniq
          ?.filter((item) => !!item)
          ?.map((item, i) => <div key={i}>{getSalesChannelsById(item)}</div>);
      } else {
        const rowParentAccountId = row?.original?.data[1];
        const foundElements = marketingMappingElements?.filter(
          (item: any) =>
            item?.accountId === rowParentAccountId && !item?.campaignId
        );
        const foundSourceItem = foundElements?.find(
          (item) =>
            !!item?.salesChannels?.length && item?.salesChannels?.length > 0
        );

        let seenNames: string[] = []
        const allChannels = foundElements
          ?.map((item) => item?.salesChannels?.map((channel) => channel))
          ?.flat();
        // @ts-ignore
        const uniq = [...new Set(allChannels)]?.filter((item) => !!item);
        if (!!allChannels && allChannels?.length > 0) {
          return uniq
            ?.filter((item) => !!item)
            ?.map((item, i) => {
              const names = getSalesChannelsById(item);
              if(seenNames?.some((name) => names?.some((subName) => subName === name))){
                return
              }
              seenNames = [...seenNames, ...names]

              return (<Flex alignItems={"center"} key={i}>
                {getSalesChannelsById(item)}
                {!!foundSourceItem &&
                foundSourceItem?.source === MarketingMappingSource.AUTO ? (
                  <styles.DefaultBadge>Default</styles.DefaultBadge>
                ) : (
                  <></>
                )}
              </Flex>)
            });
        }
      }
    }

    return <></>;
  };

  const UrlModal = () => {
    let urls: any[] = [];
    if (urlModalData?.depth === 0) {
      const platformUrls = data?.filter(
        (item) => item?.platform === urlModalData?.key
      );
      const allUrls = platformUrls?.map((item) => {
        return item?.urls;
      });
      urls = allUrls;
    }
    if (urlModalData?.depth === 1) {
      const platformUrls = data?.filter(
        (item) => item?.wvAccountId === urlModalData?.key
      );
      const allUrls = platformUrls?.map((item) => {
        return item?.urls;
      });
      urls = allUrls;
    }
    if (urlModalData?.depth === 2) {
      const platformUrls = data?.filter(
        (item) => item?.campaignId === urlModalData?.key
      );
      const allUrls = platformUrls?.map((item) => {
        return item?.urls;
      });
    }
    let uniqueUrls = urls
      ?.filter((c, index) => {
        return urls?.indexOf(c) === index;
      })
      ?.flat()
      ?.filter((item) => !!item);

    const UrlRow = ({ url }: { url?: string }) => {
      return (
        <styles.UrlRow>
          <styles.Url rel="noreferrer noopener" target="_blank" href={url}>
            {url}
          </styles.Url>
        </styles.UrlRow>
      );
    };

    return (
      <>
        <Modal isOpen={isUrlModalOpen} onClose={onUrlModalClose}>
          <ModalOverlay />
          <ModalContent backgroundColor={"#FBF7FF"} borderRadius={10}>
            <ModalHeader padding={"0 !important"}>
              <styles.ModalHeaderFlex>
                {linksIcon()}
                <styles.ModalUrlTitle>Links</styles.ModalUrlTitle>
              </styles.ModalHeaderFlex>
            </ModalHeader>

            <ModalBody
              padding={"0 !important"}
              w={"100%"}
              backgroundColor={"white"}
              borderRadius={"10px"}
            >
              {uniqueUrls?.length === 0 ? (
                <styles.UrlRow>
                  <Box padding={"10px"}>No Links</Box>
                </styles.UrlRow>
              ) : (
                <Flex
                  w={"100%"}
                  flexDirection={"column"}
                  maxH={240}
                  overflowY={"auto"}
                >
                  {uniqueUrls.map(
                    (item, i) => (
                      <UrlRow key={i} url={item} />
                    )
                    /*<styles.Url rel="noreferrer noopener" target="_blank" href={item}  key={i}>{item}</styles.Url>*/
                  )}
                </Flex>
              )}
              <Box h={2} w={"100%"} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  };

  const RenderMappingModal = () => {
    return <MarketingModal isOpen={isOpen} onClose={onClose} row={row} />;
  };

  const onRowClick = (row: Row) => {
    if (row?.canExpand) {
      if (row?.id in allExpendedRows) {
        setAllExpendedRows((prev) => {
          return { ...prev, [row.id]: !prev[row?.id] };
        });
      } else {
        setAllExpendedRows((prev) => {
          return { ...prev, [row.id]: true };
        });
      }
    }
  };

  function Table({
    columns: userColumns,
    data,
    onRowClick,
  }: {
    columns: any;
    data: any;
    onRowClick: any;
  }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state: { expanded },
    } = useTable(
      {
        columns: userColumns,
        data,
        initialState: { expanded: allExpendedRows },
      },
      useExpanded // Use the useExpanded plugin hook
    );

    const tableRows = React.useMemo(
      () =>
        rows?.map((row: any, i) => {
          prepareRow(row);
          return (
            <styles.StyledTr
              depth={row.depth > 0}
              onClick={() => {
                onRowClick(row);
              }}
              {...row.getRowProps()}
            >
              {row.cells.map((cell: Cell, i: number) => {
                if (cell.column.id === "expander") {
                  return;
                }
                if (cell.column.id === "salesChannel") {
                  return (
                    <styles.StyledTd
                      {...row.getToggleRowExpandedProps({ title: undefined })}
                      {...cell.getCellProps}
                    >
                      <styles.StyledDiv>
                        <styles.StyledDivHolder>
                          {checkRow(row)}
                        </styles.StyledDivHolder>
                      </styles.StyledDiv>
                    </styles.StyledTd>
                  );
                }
                /*if(cell.column.id === "products") {
                                      return <td style={{display: "hidden"}}></td>;
                                  }*/
                if (cell.column.id === "url") {
                  return (
                    <styles.StyledTd
                      w={3}
                      {...row.getToggleRowExpandedProps({ title: undefined })}
                      {...cell.getCellProps()}
                    >
                      <styles.URLbutton
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          let key = "";
                          if (row?.depth === 0) {
                            key = row?.original?.data[0];
                          }
                          if (row?.depth === 1) {
                            key = row?.original?.data[1];
                          }
                          if (row?.depth === 2) {
                            key = row?.original?.data[2];
                          }
                          onUrlModalOpen();
                          setUrlModalData({ depth: row?.depth, key: key });
                        }}
                      >
                        Watch URL
                      </styles.URLbutton>
                    </styles.StyledTd>
                  );
                }
                if (cell?.column?.id === "edit") {
                  if (row.depth === 0) {
                    return (
                      <styles.StyledTd
                        /*{...row.getToggleRowExpandedProps({title: undefined})}*/ {...cell.getCellProps()}
                      ></styles.StyledTd>
                    );
                  }
                  return (
                    <styles.StyledTd
                      onClick={(e) => {}}
                      /*{...row.getToggleRowExpandedProps({title: undefined})}*/ {...cell.getCellProps()}
                    >
                      <Flex
                        w={"100%"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <styles.Edit
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onOpen();
                            setRow(row);
                          }}
                        >
                          Edit
                        </styles.Edit>
                      </Flex>
                    </styles.StyledTd>
                  );
                }
                return (
                  <styles.StyledTitleTd
                    {...row.getToggleRowExpandedProps({ title: undefined })}
                    {...cell.getCellProps()}
                  >
                    <div
                      style={
                        cell.column.id === "title"
                          ? {
                              fontWeight: "bold",
                              padding: `0 0 0 ${row.depth * 25}px`,
                              display: "flex",
                              alignItems: "center",
                            }
                          : {}
                      }
                    >
                      <Flex alignItems={"center"}>
                        <Box w={10} />
                        {cell?.column?.id === "title"
                          ? getSvg(cell.value)
                          : null}
                        {row.depth === 0 ? (
                          cell.render("Cell")
                        ) : row.depth === 1 ? (
                          accounts?.data?.find((item) => item.id === cell.value)
                            ?.displayName
                        ) : cell.column.id === "title" ? (
                          <Tooltip label={row?.original?.displayName}>
                            <styles.CampaignTitleHolder>
                              {row?.original?.displayName}
                            </styles.CampaignTitleHolder>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                        {row.isExpanded &&
                        cell.column.id === "title" &&
                        row.depth !== 2 ? (
                          <Flex flexDirection={"column"}>
                            <Box h={"1px"} w={1} />
                            <IoIosArrowDown />
                          </Flex>
                        ) : (
                          ""
                        )}

                        {!row.isExpanded &&
                        cell.column.id === "title" &&
                        row.depth !== 2 ? (
                          <Flex flexDirection={"column"}>
                            <Box h={"1px"} w={1} />
                            <IoIosArrowForward />
                          </Flex>
                        ) : (
                          ""
                        )}
                      </Flex>
                    </div>
                  </styles.StyledTitleTd>
                );
              })}
            </styles.StyledTr>
          );
        }),
      [rows]
    );

    return (
      <styles.StyledTable {...getTableProps()}>
        <styles.StyledThead>
          {headerGroups.map((headerGroup, i) => (
            <styles.StyledHeaderTr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                if (column.id === "expander") {
                  // eslint-disable-next-line array-callback-return
                  return;
                }
                if (column.id === "products") {
                  // eslint-disable-next-line array-callback-return
                  return;
                }
                if (column.id === "url") {
                  return (
                    <styles.StyledTh w={10} {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </styles.StyledTh>
                  );
                }

                if (column.id === "title") {
                  return (
                    <styles.StyledTh
                      w={25}
                      center={true}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </styles.StyledTh>
                  );
                }
                return (
                  <styles.StyledTh
                    style={column.id === "title" ? { textAlign: "left" } : {}}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </styles.StyledTh>
                );
              })}
            </styles.StyledHeaderTr>
          ))}
        </styles.StyledThead>
        <tbody {...getTableBodyProps()}>{tableRows}</tbody>
      </styles.StyledTable>
    );
  }

  const getHeaders = () => {
    return [
      {
        id: "expander", // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <span {...getToggleAllRowsExpandedProps()}></span>
        ),
        Cell: ({ row }: any) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                },
                title: undefined,
              })}
            ></span>
          ) : null,
      },
      {
        Header: "Marketing Channel",
        accessor: "title",
      },
      {
        Header: "Sales Channel",
        accessor: "salesChannel",
      },
      {
        Header: "URL",
        accessor: "url",
      },
      /*{
                    Header: 'Type',
                    accessor: 'type',
                },*/
      {
        Header: "",
        accessor: "edit",
      },
    ];
  };

  const columns = React.useMemo(() => getHeaders(), []);

  const tableData = React.useMemo(() => nestedData(data), [data]);

  return (
    <styles.TableHolder style={{ width: "95%" }}>
      <RenderMappingModal />
      <UrlModal />
      {<Table columns={columns} data={tableData} onRowClick={onRowClick} />}
    </styles.TableHolder>
  );
};

export default React.memo(MarketingTable);
