import {PlanningTableData} from "components/planning-graph/planning_table/PlanningTable";
import {PlanningData} from "components/Planning/Planning";
import {Kpi, MarketingKpi, WebsiteKpi} from "models/kpi";
import {PnlReportGroupBy, PnlReportShowBy, SummarizeResponseDto} from "../../services/kpi/kpi.service";

// state

interface Order {
    wvChannelId: string;
    orderId: string;
    purchasePoint: string;
    orderDate: string;
    productName: string;
    sku: string;
    qty: string;
    totalPaid: number;
    id: string;
}

export interface MarketingMappingData {
    _id?: string,
    wvAccountId?: string,
    accountId?: string,
    accountName?: string,
    campaignId?: string,
    campaignName?: string,
    platform?: string,
    urls?: string[]
}

export enum KpiGroupBy {
    CHANNEL_DISPLAY_NAME,
    CHANNEL_ID
}

export interface TableMetadata {
    tableName?: string;
    rows?: TableMetaDataRows[]
    _id?: string;
}

export interface Attribute {
    key: string,
    value: string
}

export interface TableMetaDataRows {
    fieldName?: string;
    attribute?: Attribute[]
}

export interface KpiState {
    groupBy: KpiGroupBy,
    summedData?: {
        dailyStats?: any;
        marketingStats?: any;
        websiteStats?: any;
        targetStats?: any;
    };
    forecast?: {
        data: Kpi[];
        count?: number;
    };
    bigWideviewCardsData?: SummarizeResponseDto[];
    data?: {
        data: Kpi[];
        count?: number;
    };
    exploreData?: {
        data: Kpi[];
        count?: number;
    };
    lastPeriod?: {
        data: Kpi[];
        count?: number;
    };
    customStats?: {
        data: Kpi[];
        count?: number;
    };
    financeKpi?: {
        data: Order[];
        count: number;
    };
    marketing?: {
        data: MarketingKpi[];
        count?: number;
    };
    sales?: {
        data: Kpi[];
        count?: number;
    };
    website?: {
        data: WebsiteKpi[];
        count?: number;
    };
    targetData?: {
        data: Kpi[];
        count?: number;
    };
    pnlReports?: { data?: { name?: string, Total?: number }[] };
    blendedRoasReports?: {
        data?: {
            [key: string]: number | string;
            name: string;
        }[]
    };
    cpoReports?: {
        data?: {
            [key: string]: number | string;
            name: string;
        }[]
    };
    customersReport?: {
        data?: {
            [key: string]: number | string;
            name: string;
        }[]
    };
    isPnlTableLoading: boolean;
    isBlendedRoasTableLoading: boolean;
    pnlTableConfig?: { data?: TableMetadata[] };
    blendedRoasTableConfig?: { data?: TableMetadata[] };
    cpoTableConfig?: { data?: TableMetadata[] };
    cacTableConfig?: { data?: TableMetadata[] };
    pnlPageFilters?: {
        groupBy: PnlReportGroupBy,
    }

    financeDaily?: any;
    kpiRenderLoader: boolean;
    loading: boolean;
    marketingStatsLoading: boolean;

    marketingMappingData?: MarketingMappingData[];
    marketingLoader: boolean;
    exploreLoading: boolean;
    customTargetsTable: {
        data?: PlanningData[];
        channels?: string[];
        isLoading?: boolean;
        isSuccess?: boolean;
        id?: string;
    };
    graphData?: {
        data?: {
            grossRevenue?: number,
            netRevenue?: number,
            grossProfit?: number,
            contributionProfit?: number
        },
        targets?: {
            grossRevenue?: number,
            netRevenue?: number,
            grossProfit?: number,
            contributionProfit?: number
        },

    }

}

// initial state
export const initialKpiState: KpiState = {
    groupBy: KpiGroupBy.CHANNEL_ID,
    summedData: {
        dailyStats: undefined,
        marketingStats: undefined,
        websiteStats: undefined,
        targetStats: undefined,
    },
    blendedRoasReports: {
        data: [],
    },
    cpoReports: {
        data: [],
    },
    customersReport: {
        data: [],
    },
    marketingMappingData: [],
    customStats: undefined,
    targetData: undefined,
    data: undefined,
    financeKpi: undefined,
    marketing: undefined,
    website: undefined,
    financeDaily: undefined,
    kpiRenderLoader: false,
    loading: false,
    marketingStatsLoading: false,
    pnlPageFilters: {
        groupBy: PnlReportGroupBy.DAY,
    },
    isPnlTableLoading: false,
    isBlendedRoasTableLoading: false,
    marketingLoader: false,
    customTargetsTable: {
        data: undefined,
        channels: undefined,
        isLoading: false,
        isSuccess: undefined,
        id: undefined,
    },
    bigWideviewCardsData: [],
    exploreLoading: false,
};
